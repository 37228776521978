body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #171941 !important;
  max-width: 100%;
  overflow-x: hidden;
}

:root {
  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-ms: 90%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

section {
  margin-top:8rem;
  height: 100vh;
}

/*=====General Styles====*/
.container { 
  width:var(--container-width-lg);
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
}

h1 { 
  font-size: 2.5 rem;
}


section > h2, section > h5 {
  text-align: center
}

section > h2 { 
  margin-bottom: 3rem;
}