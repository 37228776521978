.presentation-page .page-header {
    height: 100vh;
}

.page-header {
    min-height: 100vh;
    padding: 0;
    color:#fff;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.bi {
    margin: 30px 40px 0px;
    transition: transform .3s;
}

.bi:hover {
    color:#01b159;
    cursor: pointer;
    transform: scale(1.15);
    transition: 0.3s ease;
    text-decoration: none;
}

.brand {
    position: absolute;
    top:50%;
    left:50%;
    z-index: 2;
    transform: translate(-50%, -50%);
    text-align: center;
    color:#fff;
    padding: 0 15px;
}

footer {
    padding: 3rem 0;
    text-align: center;
    background: #01b159;
    height:20vh;
    transform: skewY(3.0deg);
    
}

footer p {
    transform: skewY(-3.0deg);
    color: #fff;
    font-size: 16px;
}

.footer-link {
    color: rgb(52, 52, 52);
}

.footer-link:hover {
    color: rgb(182, 182, 182);
    cursor: pointer;
    transform: scale(1.01);
    transition: 0.3s ease;
    text-decoration: none;
}

.bottom-filler {
    margin-top: -100px;
    height: 25vh;
    background: #01b159;
}

.h1 {
    line-height: 1.05;
    margin-bottom: 30px;
    font-size: 5em;
}

.image {
    display: table; 
    margin: 50px auto;
    border: 2px solid #01b159;
    border-radius: 50%;
    height: 180px;
    width: 180px;
}

.path {
    position: absolute;
    opacity: .02;
    max-width: 75%;
    right: 0;
    top: 20px;

}

.path2 {
    position: absolute;
    opacity: .02;
    max-width: 60%;
    right: -80px;
    top: -30px;
    transform: scaleX(-1);
}

.permalinks {
    list-style: none;
    font-size: 18px;
    transform: skewY(-3.0deg);
    display:flex;
    flex-wrap:wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto 3rem;
}

.profile {
    display: table;
    border-radius: 50%;
    max-width: 100%;
}

@media only screen and (max-width: 680px) {
    .bottom-filler {
        height: 35vh;
    }
}

@media only screen and (max-width: 380px) {
    .bottom-filler {
        height: 45vh;
    }
}