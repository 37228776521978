.navigation-bar {
    background: rgba(0, 0, 0, 0.3) !important;
    width: max-content !important;
    display: block !important;
    padding: 0.7rem 1.7rem !important;
    z-index: 16 !important;
    position: fixed !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    bottom: 2rem !important;
    display: flex !important;
    gap:0.8rem !important;
    border-radius: 3rem !important;
    backdrop-filter: blur(15px) !important;
}

.nav-link {
    background: transparent !important;
    padding:1.1rem !important;
    border-radius: 50% !important;
    display: flex !important;
    color: hsla(0,0%,100%,.8) !important;
    font-size: 1.3rem !important;
}

.nav-link:hover {
    background:rgba(0, 0, 0, 0.3) !important;
    color: #07aadb !important;
    cursor: pointer;
    transition: all .3s ease 0s !important;
    text-decoration: none !important;
}

.nav-link.active{
    background: #01b159 !important;
    color: hsla(0,0%,100%,.8) !important;
    transition: all .3s ease .17s !important;
    text-decoration: none !important;
}

@media only screen and (max-width: 389px) {
    .navigation-bar {
        width:270px !important;
        padding: 0.7rem 0rem !important;
        gap:0.2rem !important;
    }
    .nav-link {
        font-size: 1rem !important;
    }
}