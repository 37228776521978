.btn {
  background-color: #01b159 !important;
  border-color: #01b159 !important;
}

.btn:hover {
  background-color: #07aadb !important;
  border-color: #07aadb !important;
  color: hsla(0, 0%, 100%, 0.8);
  transform: scale(1.01);
  transition: 0.3s ease;
}

.button-center { 
  position: absolute;
  margin-top:30%;
  left:40%;
  z-index: 3 !important;
  opacity:0;
}

.button-container {
    width: 100%;
    display:grid;
    position:relative;
    margin-top: 100px;
    text-align: center;
    height: auto;
}

.carousel-control-prev {
  position: relative !important;
  float: left;
  left:10% !important;
}

.carousel-control-next {
  position: relative !important;
  float: right;
  right: 10% !important;
}

.carousel-indicators {
  top: 100%;
}

.card-title {
  margin-top: 30px;
  font-weight: 400;
  color: hsla(0, 0%, 100%, 0.8);
}

.card-content {
  margin-top: 5px;
  margin-bottom: 20px;
  font-weight: 300;
  color: #9a9a9a;
}

.card-image {
  position: relative;
  z-index: 2;
  border: 1px solid #9a9a9a;
  border-radius: 1.5rem;
  margin-top: 20px;
  max-width: 100%;
}

.carousel {
  min-height: 500px;
}

.col-md-4 {
  position: relative;
  width: 100%;
  padding-right: 35px !important;
  padding-left: 35px !important;
  transition: transform 0.5s;
}

.col-md-4 .button-demo {
  position: absolute;
  margin-top:30%;
  left:55%;
  z-index: 3 !important;
  opacity:0;
}

.col-md-4 .button-option {
  position: absolute;
  margin-top:30%;
  left:25%;
  z-index: 3 !important;
  opacity:0;
}
.col-md-4:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.col-md-4:hover .card-image{
  border: 1px solid #01b159;
  opacity: 0.5;
  transition: 0.3s ease;
}

.col-md-4:hover .btn-primary{
  transition: 0.3s ease;
  opacity:1;
}

.col-md-4:hover .button-option{
  transition: 0.3s ease;
  opacity:1;
}

.col-md-4:hover .button-center{
  transition: 0.3s ease;
  opacity:1;
}
.col-md-5 {
  flex: 0 0 40% !important;
  max-width: 40% !important;
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.presentation-page .page-header {
  height: 100vh;
}

.page-header {
  min-height: 100vh;
  max-height: 999px;
  padding: 0;
  color: #fff;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.path3 {
  position: absolute;
  opacity: 0.02;
  width: 1024px;
  height: 832px;
  right: 0;
  top: 220px;
}

.path4 {
  position: absolute;
  opacity: 0.02;
  width: 768px;
  height: 685px;
  right: 0;
  top: 75px;
}

.path6 {
  position: absolute;
  opacity: 0.02;
  width: 868px;
  height: 785px;
  right: 40%;
  top: 105px;
}

.description {
  color: #9a9a9a;
  font-weight: 300;
}

.descrBlock {
  text-align: left;
  position: relative;
  float: inline-end;
  color: #9a9a9a;
  font-weight: 300;
}

.icon {
  width: 40px;
  height: 40px;
  float:left;
  position: relative;
  margin: 15px 0 0 30px;
}

.info-horizontal {
  position: relative;
  max-width: 500px !important;
  text-align: left !important;
}

.info-horizontal .description {
  overflow: hidden;
  text-align: justify;
}

.info {
  margin: 0 auto;
  padding: 50px 0px 30px;
  border-radius: 3px;
}

.info p {
  color: #9a9a9a;
  margin-left: 70px;
  padding: 0 15px;
  font-size: 1.1em;
}

.info-title {
  margin: 25px 0 15px 70px;
  padding: 0 15px;
  color: hsla(0, 0%, 100%, 0.8);
  font-weight: 400;
}

.ml-auto {
  margin-left: auto !important;
  justify-content: center !important;
}

.slider-image {
  max-width: 480px;
  max-height: 320px;
  margin-left: 60px;
}

.slider-info {
  text-align: right;
  padding-right: 30px;
  font-size: 0.9rem;
  color: hsla(0, 0%, 100%, 0.8);
}

.slider-descr {
  padding: 30px;
  margin-bottom: 30px;
  text-align: left;
  color: #9a9a9a;
  font-weight: 300;
}

.slider-media {
  max-width: 660px;
  width: auto;
  height: auto;
}
.responsive-iframe {
  position: relative;
  top: 0;
  left: 55px;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.slider-subtitle {
  margin-top: 30px;
  margin-bottom: 25px;
  margin-left: 30px;
  font-weight: 300;
  color: hsla(0, 0%, 100%, 0.8);
  text-align: left;
}

.slider-title {
  margin-top: 50px;
  margin-bottom: 25px;
  font-weight: 600;
  color: hsla(0, 0%, 100%, 0.8);
  text-align: left;
}

.title {
  margin-top: 30px;
  margin-bottom: 25px;
  font-weight: 600;
  color: hsla(0, 0%, 100%, 0.8);
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

@media only screen and (max-width: 480px) {
  .col-md-4:hover {
    cursor: pointer;
    transform: scale(0.95) !important;
    }
  }

@media only screen and (max-width: 389px) {
  .carousel-control-prev {
    left:0% !important;
  }
  
  .carousel-control-next {
    right: 0% !important;
  }
}